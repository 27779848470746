import I18n from 'common/i18n';
import React from 'react';
import {
  ForgeButton,
  ForgeDialog,
  ForgeIcon,
  ForgeIconButton,
  ForgeScaffold,
  ForgeTextField,
  ForgeToolbar,
  ForgeTooltip
} from '@tylertech/forge-react';

const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'shared.explore_grid.save_as_modal', ...options });

interface CreateViewModalProps {
  isOpen: boolean;
  busy: boolean;
  disabled: boolean;
  currentViewName: string;
  newViewName: string;
  onChange: (val: string) => void;
  onDismiss: () => void;
  onSave: (e: React.FormEvent | React.MouseEvent) => void;
}

const CreateViewModal = ({
  isOpen,
  busy,
  disabled,
  currentViewName,
  newViewName,
  onChange,
  onDismiss,
  onSave
}: CreateViewModalProps) => {
  const disableSave = busy || newViewName.length === 0;
  const flyoutText = (
    <div className="flyout-message">
      {t('flyout_text_1')} <span className="socrata-icon-filter" /> {t('flyout_text_2')}
    </div>
  );

  if (disabled) {
    return null;
  }

  return (
    <ForgeDialog open={isOpen} onDismiss={onDismiss}>
      <div id="create-view-dataset-dialog" data-testid="create-view-dataset-dialog">
        <ForgeScaffold>
          <div slot="header">
            <ForgeToolbar>
              <h1 slot="start" id="create-view-dialog-title" className="forge-typography--headline5">
                {t('modal_title')}
              </h1>
              <ForgeIconButton slot="end">
                <button className="tyler-icons" onClick={onDismiss}>
                  <ForgeIcon name="close" />
                </button>
              </ForgeIconButton>
            </ForgeToolbar>
          </div>

          <div slot="body" className="create-view-dialog-container">
            <ForgeTextField>
              <input
                type="text"
                id="input-text-view-name"
                value={newViewName}
                onChange={(e) => onChange(e.target.value)}
                className="text-input"
              />
              <label htmlFor="input-text-view-name" slot="label">
                {t('view_name')}
              </label>
              <span className="helper-text-for-input-text" slot="helper-text">
                {t('based_on', { name: currentViewName })}
              </span>
            </ForgeTextField>
          </div>
          <div slot="footer" className="footer">
            <ForgeToolbar no-border>
              <div className="what-is-a-view-tooltip forge-typography--subtitle2-secondary" slot="start">
                {t('what_is_a_view')}
              </div>
              <ForgeTooltip target=".what-is-a-view-tooltip" position={'bottom'}>
                {flyoutText}
              </ForgeTooltip>
              <ForgeButton slot="end" type="outlined">
                <button className="cancel-button" onClick={onDismiss} disabled={busy}>
                  {t('cancel')}
                </button>
              </ForgeButton>
              <ForgeButton slot="end" type="raised" busy={busy}>
                <button className="save-button" onClick={onSave} disabled={disableSave}>
                  {t('create_view')}
                </button>
              </ForgeButton>
            </ForgeToolbar>
          </div>
        </ForgeScaffold>
      </div>
    </ForgeDialog>
  );
};

export default CreateViewModal;
